import { render, staticRenderFns } from "./VideoBackground.vue?vue&type=template&id=148dfa94&scoped=true&"
import script from "./VideoBackground.vue?vue&type=script&lang=js&"
export * from "./VideoBackground.vue?vue&type=script&lang=js&"
import style0 from "./VideoBackground.vue?vue&type=style&index=0&id=148dfa94&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "148dfa94",
  null
  
)

component.options.__file = "VideoBackground.vue"
export default component.exports